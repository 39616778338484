<template>
  <div class="view pa24">
    <div class="d-flex justify-content-between">
      <div>
        活动名称：
        <el-input
          v-model="activityName"
          class="w200 mr10"
          placeholder="请输入活动名称"
        />
        
        选择时间：
        <el-date-picker
          v-model="pickerValue"
          class="mr10"
          align="right"
          value-format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        活动状态：
        <el-select class="mr20 w150" v-model="authsId" clearable>
          <el-option
            v-for="item in auths"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            {{ item.name }}
          </el-option>
        </el-select>
        <el-button type="primary" @click="queryVisitorsData">搜索</el-button>
        <el-button @click="reset">重置</el-button>
        
      </div>
        <el-button type="primary" @click="addActivity">+ 新建活动</el-button>
    </div>
    
  <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="aeId" width="50" align="center" label="序号"> </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          width="200"
          label="活动名称"
          show-overflow-tooltip
        />
        <el-table-column prop="description" width="250" show-overflow-tooltip align="center" label="活动描述" />
        <el-table-column prop="createdTime" width="250" show-overflow-tooltip align="center" label="活动时间周期" >
          <template slot-scope="scope">
            {{ scope.row.createdTime + '-' +  scope.row.endTime  }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="活动状态">
          <template slot-scope="scope">
            {{ scope.row.status == 1 ? '未开始' : scope.row.status == 2 ? '进行中' : '已结束' }}
          </template>
        </el-table-column>
        <el-table-column prop="userNum" align="center" label="报名人数限制">
          <template slot-scope="scope">
            {{scope.row.userNum == 0 ? '不限制' : scope.row.userNum}}
          </template>
        </el-table-column>
        <el-table-column prop="joinedNum" align="center" label="已报名人数"/>
          
        <el-table-column prop="visitTime" align="center" label="活动上下架">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.isPutOn"
                active-text="上架"
                inactive-text="下架"
                @change="switchChange(scope.row)"
              >
              </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="visitTime" width="150" align="center" label="操作">
          <template slot-scope="scope">
            <div class="d-flex">
              <p class="cred hover_pointer mr20" @click="deleteActivity(scope.row)">删除</p>
              <p class="cA1 hover_pointer mr20" @click="editActivity(scope.row)">编辑</p>
              <p class="cblue hover_pointer" @click="toDetailPage(scope.row)">详情</p>
            </div>
            
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import {
  activityEventList,
  activityEventDelete,
  activityEventUpdate
} from "@/api/companyManage.js";
import { queryCompanyStaff } from "@/api/articleManagement";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      staffs: [],
      userId: "",
      auths: [
        { id: 1, name: "未开始" },
        { id: 2, name: "进行中" },
        { id: 3, name: "已结束" },
      ],
      authsId: "",
      activityName: "",
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.queryCompanyStaff();
    this.queryVisitorsData();
  },
  methods: {
    async queryCompanyStaff() {
      try {
        const result = await queryCompanyStaff({ companyId: 0 });
        this.staffs = result.data;
      } catch (error) {
        console.log(error);
      }
    },
    reset(){
      this.pickerValue = []
      this.activityName = ''
      this.authsId = ''
    },
    async queryVisitorsData() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        status: this.authsId,
        userId: this.userId,
        name: this.activityName,
        startTime: this.pickerValue && `${this.pickerValue[0]} 00:00:00`,
        endTime: this.pickerValue && `${this.pickerValue[1]} 23:59:59`,
      };
      try {
        this.loading = true;
        const result = await activityEventList(data);
        this.loading = false;
        const { total, list } = result?.data?.pageInfo;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if(theTime > 60) {
        theTime1 = parseInt(theTime/60);
        theTime = parseInt(theTime%60);
        if(theTime1 > 60) {
          theTime2 = parseInt(theTime1/60);
          theTime1 = parseInt(theTime1%60);
        }
      }
      var result = ""+parseInt(theTime)+"秒";
      if(theTime1 > 0) {
        result = ""+parseInt(theTime1)+"分"+result;
      }
      if(theTime2 > 0) {
        result = ""+parseInt(theTime2)+"小时"+result;
      }
      return result;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
    addActivity(){
      this.$router.push('addActivity')
    },
    editActivity(item){
      this.$router.push('addActivity?aeId=' + item.aeId)
    },
    deleteActivity(item){
      this.$confirm('删除活动后，所有活动相关的内容都将清除，且不可恢复，是否继续', '删除活动', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        let data ={
          aeId:item.aeId,
        }
        activityEventDelete(data)
        .then(res=>{
          if(res.code == 200){
            this.$message.success('成功取消') 
            this.queryVisitorsData()
          }
        })
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });          
      });
    },
    switchChange(item){
      console.log(item.isPutOn)
      let data = {
        isPutOn:item.isPutOn,
        aeId:item.aeId
      }
      activityEventUpdate(data)
      .then(res=>{
        if(res.code == 200){
          if(!item.isPutOn){
            this.$message.success('下架成功') 
          }else{
            this.$message.success('上架成功')
          }
          
          this.queryVisitorsData()
        }
      })
    },
    toDetailPage(item){
      this.$router.push('activityDetails?aeId=' + item.aeId)
    }
  },
};
</script>

<style lang="scss" scoped>
</style>